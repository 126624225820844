import React from "react"
import { navigate } from "gatsby"

import Layout from "../../components/admin/layout"
import Modal from "../../components/common/modal"
import request, { getUserRole } from "../../request"

const roleName = "announcer"

const blankUser = () => ({ name: "", phone: "", email: "", password: "" })

const validateEmail = email => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true
  }
  return false
}

class User extends React.Component {
  state = {
    users: [],
    reveal: false,
    isNew: true,
    user: blankUser(),
  }
  componentDidMount() {
    const role = getUserRole()
    if (role === 0) return navigate("/admin/login")
    if (role !== 1) return navigate("/admin/announcement")

    request.getUsers().then(res => {
      const users = res.data.filter(
        user => user.role && user.role.name === roleName
      )
      this.setState({ users })
    })
    request.getRoles().then(res => {
      this.roleAnnouncer = res.data.roles.find(role => role.name === roleName)
    })
  }

  addUser = () => {
    this.setState({
      reveal: true,
      user: blankUser(),
    })
  }

  editUser = user => {
    this.setState({ reveal: true, user })
  }

  deleteUser = id => {
    request.deleteUser(id).then(() => {
      this.setState({ users: this.state.users.filter(user => user.id !== id) })
    })
  }

  onCancel = () => {
    this.setState({ reveal: false })
  }

  onChange = data => {
    this.setState({ user: { ...this.state.user, ...data } })
  }

  onKeyDown = e => {
    if (e.keyCode === 13) {
      this.onConfirm()
    }
  }

  onConfirm = () => {
    const { user } = this.state
    if (
      !user.name ||
      !user.phone ||
      !user.email ||
      (!user.id && !user.password)
    ) {
      return alert("所有欄位皆須填寫!!")
    }

    if (!validateEmail(user.email)) {
      return alert("email格式不正確!!")
    }

    if (user.id) {
      const data = {
        name: user.name,
        phone: user.phone,
      }
      if (user.password) {
        data["password"] = user.password
      }
      request.putUser(user.id, data).then(res => {
        this.setState({
          reveal: false,
          users: [...this.state.users, res.data],
        })
      })
    } else {
      request
        .postUser({
          name: user.name,
          phone: user.phone,
          email: user.email,
          username: user.email,
          password: user.password,
          role: this.roleAnnouncer,
        })
        .then(res => {
          this.setState({
            reveal: false,
            users: [...this.state.users, res.data],
          })
        })
        .catch(() => {
          alert("此E-mail已被其他使用者登記使用")
        })
    }
  }

  render() {
    if (!getUserRole()) return null

    const { users, reveal, user } = this.state

    return (
      <Layout>
        <div className="ad-common-controls">
          <button onClick={this.addUser}>新增使用者</button>
        </div>
        <hr />
        <br />
        <table className="ad-user__table ad-user__table--hover">
          <thead>
            <tr>
              <th>姓名</th>
              <th>電話</th>
              <th>E-mail</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id} onClick={() => this.editUser(user)}>
                <td>{user.name}</td>
                <td>{user.phone}</td>
                <td>{user.email}</td>
                <td>
                  <button
                    onClick={e => {
                      e.stopPropagation() // don't trigger row click
                      // eslint-disable-next-line no-restricted-globals
                      if (confirm("確定刪除此使用者?")) {
                        this.deleteUser(user.id)
                      }
                    }}
                  >
                    刪除
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          reveal={reveal}
          size="md"
          header={user.id ? "編輯留言板使用者" : "新增留言板使用者"}
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
          textConfirm={user.id ? "更新" : "新增"}
        >
          <div className="ad-user__modal">
            <form className="ad-user__form">
              <div className="ad-user__form-item">
                <div className="ad-user__form-text">姓名</div>
                <input
                  className="ad-user__form-input"
                  type="text"
                  placeholder="例:王大明"
                  value={user.name}
                  onKeyDown={this.onKeyDown}
                  onChange={e => this.onChange({ name: e.target.value })}
                />
              </div>
              <div className="ad-user__form-item">
                <div className="ad-user__form-text">電話</div>
                <input
                  className="ad-user__form-input"
                  type="text"
                  placeholder="例:0912345678"
                  value={user.phone}
                  onKeyDown={this.onKeyDown}
                  onChange={e => this.onChange({ phone: e.target.value })}
                />
              </div>
              <div className={`ad-user__form-item ${user.id ? "hidden" : ""}`}>
                <div className="ad-user__form-text">
                  <div>E-mail</div>
                  <div>(登入帳號)</div>
                </div>
                <input
                  className="ad-user__form-input"
                  type="email"
                  placeholder="例:taia2.mail@msa.hinet.net"
                  value={user.email}
                  onKeyDown={this.onKeyDown}
                  onChange={e => this.onChange({ email: e.target.value })}
                />
              </div>
              <div className="ad-user__form-item">
                <div className="ad-user__form-text">
                  {user.id ? "更新密碼" : "登入密碼"}
                </div>
                <input
                  className="ad-user__form-input"
                  type="password"
                  autoComplete="no"
                  value={user.id ? "" : user.password}
                  onKeyDown={this.onKeyDown}
                  placeholder={user.id ? "若無需修改密碼請留空白" : ""}
                  onChange={e => this.onChange({ password: e.target.value })}
                />
              </div>
            </form>
          </div>
        </Modal>
      </Layout>
    )
  }
}

export default User
